import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'KRS',
    lpAddresses: {
      97: '',
      56: '0x52647fF91BD3d1476Ea9abDE2CEd73bF713d8b71',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x52647fF91BD3d1476Ea9abDE2CEd73bF713d8b71',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'KRS-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x2C1a675d2bB37EddB0Dd0BEA2C4e078aA43a6286',   // lp address token-bnb
    },
    tokenSymbol: 'KRS',
    tokenAddresses: {
      97: '',
      56: '0x52647fF91BD3d1476Ea9abDE2CEd73bF713d8b71', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'KRN-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x3DA22a4a4A2C5aC1b646226e24151205223fa73D',   // lp address token-bnb
    },
    tokenSymbol: 'KRN',
    tokenAddresses: {
      97: '',
      56: '0x4e6d79CDdEc12C229D53b38c11B204bcec118885', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  // {
  //   pid: 2,
  //   lpSymbol: 'KRN-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3DA22a4a4A2C5aC1b646226e24151205223fa73D',   // lp address token-bnb
  //   },
  //   tokenSymbol: 'KRN',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x4e6d79CDdEc12C229D53b38c11B204bcec118885', // token address
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
]

export default farms
